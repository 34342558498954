import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BASE_URL, BASE_URL_NODE } from 'src/config';
// import { BASE_URL } from 'src/config';
import useSWR from 'swr';

// ----------------------------------------------------------------------
const makeCall = async (url, request) => fetch(url, request).then((res) => res.json());

export default function useFetch(request, { fallbackData, ...config } = {}) {
	const user = useSelector((state) => state.auth.authData);

	const {
		data: response,
		error,
		isValidating,
		mutate,
	} = useSWR(request && JSON.stringify(request), () => makeCall(getBaseUrl(request.url) + request.url, request), {
		...config,
		fallbackData: fallbackData && {
			status: 200,
			statusText: 'InitialData',

			config: request,
			headers: {
				Authorization: 'Bearer ' + user?.auth_token || '',
			},
			data: fallbackData,
		},
	});

	return {
		data: response,
		response,
		error,
		isValidating,
		mutate,
	};
}

const fetcher = (...args) => {
	console.log('args:: ', args);
	const f = fetch(...args).then((res) => res.json());
	return f;
};

export function useFetch_new(request, config) {
	const user = useSelector((state) => state.auth.authData);

	const url = BASE_URL + request.url;

	const payload = {
		headers: {
			Authorization: 'Bearer ' + user?.auth_token || '',
		},
	};
	const { data: response, error, isValidating, mutate } = useSWR([url, payload], fetcher, config);

	return {
		data: response,
		response,
		error,
		isValidating,
		mutate,
	};
}

const getBaseUrl = (path) => {
	if (path.includes('/n/')) {
		return BASE_URL_NODE;
	}
	return BASE_URL;
};

export async function useFetch_v3(request, config) {
	const user = useSelector((state) => state.auth.authData);

	let url = getBaseUrl(request.url) + request.url;
	if (request.queryParms) {
		let qp = new URLSearchParams(request.queryParms).toString();
		url = `${url}?${qp}`;
	}

	if (!request.method) {
		request.method = 'GET';
	}
	let header = request.header;
	if (request.body && ['POST'].includes(request.method)) {
		header = { ...header, Accept: 'application/json', 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', Authorization: 'Bearer ' + user?.auth_token || '' };
		request = {
			...request,
			body: JSON.stringify(request.body),
		};
	}

	if (request.method == 'GET') {
		header = { ...header, Accept: 'application/json', 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', Authorization: 'Bearer ' + user?.auth_token || '' };
	}
	const headers = {
		headers: header,
	};

	const { data: response, error, isValidating, mutate } = useSWR([url, { ...headers, ...request }], fetcher, config);
	return {
		data: response,
		response,
		error,
		isValidating,
		mutate,
	};
}

// export async function useFetchPost(
// 	{ url, payload, queryParms },
// 	config = {
// 		revalidateOnFocus: false,
// 	}
// ) {
// 	const user = useSelector((state) => state.auth.authData);

// 	let _url = getBaseUrl(url) + url;
// 	let request = {
// 		_url,
// 	};

// 	if (queryParms) {
// 		_url.search = new URLSearchParams(queryParms).toString();
// 	}

// 	if (request.body && ['POST'].includes(request.method)) {
// 		header = { ...header, Accept: 'application/json', 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', Authorization: 'Bearer ' + user?.auth_token || '' };
// 		request = {
// 			...request,
// 			body: JSON.stringify(payload),
// 		};
// 	}

// 	if (request.method == 'GET') {
// 		header = { ...header, Accept: 'application/json', 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', Authorization: 'Bearer ' + user?.auth_token || '' };
// 	}
// 	const headers = {
// 		headers: header,
// 	};

// 	const { data: response, error, isValidating, mutate } = useSWR([url, { ...headers, ...request }], fetcher, config);
// 	return {
// 		data: response,
// 		response,
// 		error,
// 		isValidating,
// 		mutate,
// 	};
// }

export const useFetchGet = (url, request = {}) => {
	const { queryParms = {} } = request;
	const user = useSelector((state) => state.auth.authData);

	//React useState Hooks
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [error, setError] = useState(null);

	let _url = new URL(getBaseUrl(url) + url);
	_url.search = new URLSearchParams(queryParms).toString();

	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + user?.auth_token,
	};
	useEffect(() => {
		const getData = async () => {
			try {
				const response = await fetch(_url, { headers });
				const results = await response.json();
				setLoading(false);
				setData(results);
				setError(false);
			} catch (error) {
				setLoading(false);
				setError(false);
				setError(error);
			}
		};

		getData();
	}, [url]);

	return {
		data,
		error,
		loading,
	};
};

/*
	EXAMPLE: for only POST request
	1. useFetchPost(url)
	2. useFetchPost(url, {
		queryParms: {  // optional
			...
		},
		payload: {    // optional
			...
		}
	})
*/
export const useFetchPost = (url, request = {}) => {
	const { payload = {}, queryParms = {} } = request;

	//React useState Hooks
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [error, setError] = useState(null);

	const user = useSelector((state) => state.auth.authData);
	let _url = new URL(getBaseUrl(url) + url);
	_url.search = new URLSearchParams(queryParms).toString();

	let body = JSON.stringify(payload);
	let headers = {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + user?.auth_token,
	};
	useEffect(() => {
		const getData = async () => {
			try {
				const response = await fetch(_url, { method: 'POST', body, headers });
				const results = await response.json();
				setLoading(false);
				setData(results.data);
				setError(false);
			} catch (error) {
				setLoading(false);
				setError(false);
				setError(error);
			}
		};

		getData();
	}, [url]);

	return {
		loading,
		data,
		error,
	};
};
