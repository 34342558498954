export const Endpoint = {
	chat: {
		register: '/api/auth/chat/getToken/#',
		getToken: '/api/auth/chat/getToken/#uid',
	},
	// Authentication
	login: '/api/n/public/login',
	register: '/api/n/public/register',
	forgotPassword: '', //'/api/public/forgotPassword',
	profile: '', //'/api/auth/client',

	// Freelancer
	upload: '/api/n/upload/avatar',
	createProfile: '/api/n/freelancer/profile', //'/api/auth/freelancer',
	getProfile: '/api/n/public/freelancer/:fPublicId', //'/api/auth/freelancer/<user_id>',

	// client
	getClientProfile: '/api/auth/client/<public_id>', // TODO

	// freelancer gig
	createGig: '/api/n/gig', //'/api/auth/freelancer/gig',
	getGig: '/api/n/gig/', //'/api/auth/freelancer/gig', // TODO: to implement
	uploadGig: '/api/auth/freelancer/gig/<user_id>/uploadFile', // TODO
	preload: '/api/auth/freelancer/gig/preload', // TODO
	allGig: '/api/n/gig/all/:fPublicId', //'/api/auth/freelancer/gig/all',

	// Bid
	postBid: '/api/auth/freelancer/bid',

	freelancer: {
		allBids: '/api/n/bidForFreelancer/#fPublicId',
		search: '/api/n/public/searchFreelancer', // post
	},

	//post project
	createProject: '/api/auth/client/requirement',
	getProject: '/api/auth/client/requirement/<project_id>',
	updateProject: '/api/auth/client/requirement',
	getAllProject: '/api/auth/client/requirement/all',
	delProject: '/api/auth/client/requirement',

	//others
	findaddress: 'https://data.opendatasoft.com/api/records/1.0/search/?dataset=geonames-postal-code%40public&q=postal_code%3D<zipcode>&rows=1',

	//Payment
	razorpay: 'http://localhost:4000/api/payment',
};
