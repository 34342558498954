
import { BASE_URL, BASE_URL_NODE } from 'src/config';
// import { Endpoint } from './urls';

class Http {
	userDetail() {
		const data = JSON.parse(localStorage.getItem('persist:root'));
		const data2 = JSON.parse(data?.auth);
		const user = data2?.authData;
		return {
			...user,
		};
	}

	authHeader() {
		const user = this.userDetail();
		console.log(user);
		return {
			Authorization: 'Bearer ' + user?.auth_token,
		};
	}

	async localFetch(url, method, payload, header = null) {
		console.log('localhost fetch');
		const user = this.userDetail();
		// const user = store.get(StoreConst.USER)
		let options = {
			method: method,
			body: JSON.stringify(payload),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
		};
		if (header) {
			options.headers = { ...options.headers, ...header };
		}
		if (user && user.auth_token) {
			options.headers = { ...options.headers, Authorization: 'Bearer ' + user.auth_token };
		}

		try {
			const response = await fetch(url, { ...options });
			return await response.json();
		} catch (error) {
			console.log('LOCAL FETCH ERROR 👇👇');
			console.log('errror is: ', error);

			return null;
		}
	}

	async uploadFile(url, form, header = null) {
		const user = this.userDetail();
		let options = {
			method: 'POST',
			body: form,
			headers: {
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
			},
		};
		if (header) {
			options.headers = { ...options.headers, ...header };
		}
		if (user && user.auth_token) {
			options.headers = { ...options.headers, Authorization: 'Bearer ' + user.auth_token };
		}

		try {
			const response = await fetch(url, { ...options });
			return response;
		} catch (error) {
			console.log('LOCAL FETCH ERROR 👇👇');
			console.log('errror is: ', error);

			return null;
		}
	}

	handleRequest(parms) {
		return parms;
	}

	handleSuccess(response) {
		return response.data;
	}

	handleError = (error) => {
		// console.log(error.response.data)
		switch (error?.response?.status) {
			case 400:
				return Promise.reject(error.response);
			case 401:
				this.redirectTo(document, '/');
				break;
			case 404:
				this.redirectTo(document, '/404');
				break;
			default:
				this.redirectTo(document, '/500');
				break;
		}
		return Promise.reject(error?.response || error?.message);
	};

	redirectTo = (document, path) => {
		document.location = path;
	};

	getBaseUrl = (path) => {
		if (path.includes('/n/')) {
			return BASE_URL_NODE;
		}
		return BASE_URL;
	};

	async get(path, callback) {
		// console.log('BASE_URL', BASE_URL);
		return this.localFetch(this.getBaseUrl(path) + path, 'GET')
			.then((response) => response)
			.catch((_) => false);
	}

	async patch(path, payload) {
		return fetch(this.getBaseUrl(path) + path, {
			method: 'POST',
			body: payload,
		}).then((response) => response.json());
	}

	async post(path, payload) {
		return this.localFetch(this.getBaseUrl(path) + path, 'POST', payload);
	}

	async put(path, payload) {
		return this.localFetch(this.getBaseUrl(path) + path, 'PUT', payload);
	}

	async delete(path, payload) {
		return this.localFetch(this.getBaseUrl(path) + path, 'DELETE', payload);
	}

	async postFile(path, form, header = null) {
		const f = this.uploadFile(this.getBaseUrl(path) + path, form)		
		const g = await f
		return g

		// return fetch(this.getBaseUrl(path) + path, {
		// 	method: 'POST',
		// 	body: form,
		// 	headers: {
		// 		'Content-Type': 'multipart/form-data',
		// 	},
		// })
		// 	.then((response) => response.json())
		// 	.catch(this.handleError);
	}
	// async postFileWithProgresser() {
	//   .then(res => consume(res.body.getReader()))
	// }
}

export default new Http();
