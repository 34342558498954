// import http from './http';
import axios from 'axios';
import http from './http.js';
import { Endpoint } from './urls.js';
import { useFetchGet, useFetchPost, useFetch_v3 } from 'src/hooks/useFetch';
// import { useFetch_v3 } from 'src/hooks/useFetch';
class FreelancerService {
	async upload(form) {
		return await http.postFile(Endpoint.upload, form);
	}

	async uploadGig(form, userId) {
		return await http.postFile(Endpoint.uploadGig.replace('<user_id>', userId), form);
	}

	async createProfile(parms) {
		return await http.post(Endpoint.createProfile, parms);
	}

	async getProfile(parms) {
		return await http.get(Endpoint.getProfile.replace(':fPublicId', parms));
	}

	async getAddressByZip(zip) {
		return axios.get(`https://public.opendatasoft.com/api/records/1.0/search/?dataset=geonames-postal-code&q=${zip}`).then((res) => res);

		// ALL POSTAL CODES API
		// https://public.opendatasoft.com/explore/dataset/geonames-postal-code/api/?q=75850&rows=100

		// https://data.opendatasoft.com/api/records/1.0/search/?dataset=geonames-postal-code%40public&q=postal_code%3D${zip}&rows=1
	}

	async getCountryNameByCode(code) {
		return axios.get(`https://public.opendatasoft.com/api/records/1.0/search/?dataset=countries-codes&q=${code}`).then((res) => res);

		// ALL POSTAL CODES API
		// https://public.opendatasoft.com/explore/dataset/geonames-postal-code/api/?q=75850&rows=100

		// https://data.opendatasoft.com/api/records/1.0/search/?dataset=geonames-postal-code%40public&q=postal_code%3D${zip}&rows=1
	}

	async searchCountries(query) {
		return axios.get(`https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-world-country&q=${query}&rows=100`).then((res) => res);

		// ALL POSTAL CODES API
		// https://public.opendatasoft.com/explore/dataset/geonames-postal-code/api/?q=75850&rows=100

		// https://data.opendatasoft.com/api/records/1.0/search/?dataset=geonames-postal-code%40public&q=postal_code%3D${zip}&rows=1
	}

	async getFreelancerGig(body) {
		return await http.post(Endpoint.freelancer.search, body);
	}

	// async getAllGig(fPublicId) {
	// 	return await http.get(Endpoint.allGig.replace(':fPublicId', fPublicId));
	// }
	getAllGig(fPublicId) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useFetchGet(Endpoint.allGig.replace(':fPublicId', fPublicId))
	}

	async getFreelancerSearch(body) {
		return await http.post(Endpoint.freelancer.search, body);
	}

	async getGig(gigId) {
		return await http.get(Endpoint.getGig + gigId);
	}

	async getAllBids(fPublicId) {
		return await http.get(Endpoint.freelancer.allBids.replace('#fPublicId', fPublicId));
	}

	// eslint-disable-next-line no-dupe-class-members
	getPrefetch(url) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useFetchGet(url);
	}

	async createGig(payload) {
		return await http.post(Endpoint.createGig, payload);
	}

	getProfileH(fId) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useFetchGet(Endpoint.getProfile.replace(':fPublicId', fId));
	}
}

export default new FreelancerService();
